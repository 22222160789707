import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { Link } from "gatsby"

import { CtaPrimary } from "../components/elements/buttons.js"
import CancelSVG from "../images/notify.svg"

const SectionTitle = styled.h2`
  font-size: 30px;
  line-height: 1.5556;
  font-weight: 700;
  color: inherit;
  margin: 0 auto;
  text-align: left;
  letter-spacing: -0.04em;
  width: 100%;
`
const ImgWrap = styled.img`
  width: 35%;
  margin-left: auto;
  margin-bottom: 20px;
  opacity: 0.3;
  z-index: -1;
  position: absolute;
  right: 10%;
  top: 25vh;
`
const BtnWrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 2em auto 0 0;
  width: 50%;
  min-width: 280px;
`
const Wrap = styled.div`
  width: 100%;
  height: 100vh;
  padding: 10%;
`
const ContentWrap = styled.div`
  width: 40%;
  min-width: 300px;
`
const P = styled.p`
  line-space: 1.666;
`
const Span = styled.span`
  color: #f50057;
`
const PaymentSuccess = () => {
  return (
    <Layout>
      <Wrap>
        <ContentWrap>
          <SectionTitle>Payment Cancelled</SectionTitle>
          <P>
            Uh oh! Looks like your payment didn't go through. If you didn't
            cancel payment, but ended up here, for sure reach out to us, and
            we'll get this sorted out!
            <br />
          </P>
          <BtnWrap>
            <CtaPrimary
              backgroundColor={"black"}
              height={"20px"}
              label={"Dashboard"}
              link={"/"}
            />
            <CtaPrimary
              backgroundColor={"#F50057"}
              height={"20px"}
              label={"Try Again"}
              link={"/app/checkout"}
            />
          </BtnWrap>
          <br />
          <br />

          <small>
            P.S. you can reach us at via this{" "}
            <Link to="/contact">
              <Span>link</Span>
            </Link>
            , or at <Span>info@99point9prep.com</Span>. You can also send us a
            text at +1-647-899-0337.
          </small>
          <ImgWrap src={CancelSVG} />
        </ContentWrap>
      </Wrap>
    </Layout>
  )
}
export default PaymentSuccess
